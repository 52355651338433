.modalbanner-main {
    /* position: relative; */

}
.test {
    padding: 5px 5px !important;
    background-color: #ffffff;
    z-index: 3 !important;
}

.modal-dialog-description {
    background-color: rgb(255, 255, 255);
    font-size: 14px !important;

}

.modal-dialog-title {
    color: rgb(39, 106, 173);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
.modalbanner-layout {
    position: relative;
    /* border-radius: 2px;
    border-width: 2px; */
    border-color: #ffffff;
}
.madalbanner-closebutton {
    z-index: 30 !important;
    position:absolute;
    right:0px;
    /* font-size: 35px; */
    width: 30px;
    height: 30px;
    color: #636262;
    background-color: rgb(255, 255, 255);
    border-radius:20px;
    /* border-width: 1px; */
}
.modalbanner-image {
    border-radius: 3px;
}