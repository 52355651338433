.main-des {
    -webkit-overflow-scrolling: touch;
    font-family: Lato, sans-serif;
    height: 100vh;
    overflow-y:auto;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.section-des {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    scroll-snap-align: start;
}

.section-des-1{
    background: linear-gradient(to top, #6e028a 20%, #8f5d8f 50%, #d1a5d1 80%);
}

.section-des-2{
    background: #007191;
    background: linear-gradient(to top, #01313f 20%, #007191 50%, #02acdb 80%);
}

.section-des-3{
    background: linear-gradient(to top, #2b1616 10%, #570000 50%, #FFE7A4 80%);

}
.section-des-4{
    background: linear-gradient(to top, #006a8a 20%, #009ac5 50%, #00c8ff 80%);
}
.section-center-block{
  display: flex;
}

.section-des-image {
    flex-shrink: 0;
    min-height: 50vh;
    min-width: 50vh;
    width: 50vw;
    height: 50vh;
    max-width: 50vh;
    max-height: 50vw;
}
.section-des-image-2 {
    flex-shrink: 0;
    min-height: 60vh;
    min-width: 60vh;
    width: 60vw;
    height: 60vh;
    max-width: 60vh;
    max-height: 60vw;
}

.section-des-image-3 {
    flex-shrink: 0;
    min-height: 60vh;
    min-width: 60vh;
    width: 60vw;
    height: 60vh;
    max-width: 60vh;
    max-height: 60vw;
}

.section-des-text {
 font-family: 'Inter', sans-serif;
 min-width: 500px;
 max-width: 700px;
 font-size: 16px;
 padding-left: 20px;
 padding-right: 20px;
 color: rgb(241, 240, 240);
 text-align: justify;
 align-self: center;
}
.section-des-text-2 {
    font-family: 'Inter', sans-serif;
    min-width: 500px;
    max-width: 700px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(241, 240, 240);
    text-align: justify;
    align-self: center;
   }
   .section-des-text-3 {
    font-family: 'Inter', sans-serif;
    min-width: 500px;
    max-width: 700px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(241, 240, 240);
    text-align: justify;
    align-self: center;
   }
   .section-four-text{
    font-family: 'Inter', sans-serif;
    min-width: 320px;
    max-width: 320px;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(19, 19, 19);
    text-align:left;
    align-self: center;
   }

   .section-four {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    scroll-snap-align: start;
}

.section-des-image-3 {
width: 300px;
height: 300px;
}

.shadow {
    filter: drop-shadow( 3px 3px 2px rgba(202, 80, 80, 0.7));
}

.section-two{
    padding: 40px;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
}

.myButton {
	background:linear-gradient(to bottom, #ff7369 5%, #330400 100%);
	background-color:#f24537;
	border-radius:13px;
	border:1px solid #d02718;
	display:inline-block;
	cursor:pointer;
	color:#f1f1f1;
	font-family:Verdana;
	font-size:15px;
	padding:12px 53px;
	text-decoration:none;
}
.myButton:hover {
	background:linear-gradient(to bottom, #330400 5%, #f24537 100%);
	background-color:#330400;
}
.myButton:active {
	position:relative;
	top:1px;
}
.section-begin-test{
    display: flex;
    flex-direction: column;
}
.section-begin-test-image {
    flex-shrink: 0;
    min-height: 70vh;
    min-width: 70vh;
    width: 70vw;
    height: 70vh;
    max-width: 70vh;
    max-height: 70vw;
}
.section-begin-test-text{
    justify-content: center;
    align-items: center;
    text-align: center;
}
.wrapper {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
.des-text-article-link {
   font-size: 16px;
   background-color: #FFCC33;
   border-radius: 3px;
   color: black;
   animation-name: color;
   animation-duration: 6s;
   animation-iteration-count: infinite;
}
@keyframes color {
    0% {
      background-color: #FFCC33;
    }
    50% {
      background-color: #f6e5b2;
    }
    100% {
      background-color: #FFCC33;
    }
  }

@keyframes wave {
  0% {
    transform: translate(-50%,-75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%,-75%) rotate(360deg);
  }
}
    @media (max-width: 1300px)  { // for old mac res test
    .main-des {
        -webkit-overflow-scrolling: touch;
        font-family: Lato, sans-serif;
        height: 100vh;
        overflow-y:auto;
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
    }
    }
  @media (max-width: 800px)  {
    .main-des {
        font-family: Lato, sans-serif;
        height: 90vh;
        overflow-y:auto;
        scroll-snap-type: y mandatory;
        -webkit-overflow-scrolling: touch;
    }
    .section-des {
        display: flex;
        flex-direction: column;
        height: 90vh;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        scroll-snap-align: start;
    }
    
    .section-center-block{
        margin-bottom: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .section-des-text {
        min-width: 300px;
        max-width: 350px;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: justify;
        align-self: center;
       }
       .section-des-text-2 {
        min-width: 300px;
        max-width: 390px;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: justify;
        align-self: center;
        margin-bottom: -20px;
        margin-top: -50px;
       }
       .section-des-text-3 {
        min-width: 300px;
        max-width: 350px;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: justify;
        align-self: center;
        margin-bottom: -30px;
       }
       .section-des-image {
        flex-shrink: 0;
        min-height: 40vh;
        min-width: 40vh;
        width: 40vw;
        height: 40vh;
        max-width: 40vh;
        max-height: 40vw;
    }
    .section-des-image-2 {
        flex-shrink: 0;
        min-height: 50vh;
        min-width: 50vw;
        width: 95vw;
        height: 95vh;
        max-width: 50vh;
        max-height: 50vw;
    }

    .section-two {
        display: flex;
        flex-direction:column;
    }

    .section-des-image-3 {
        flex-shrink: 0;
        min-height: 50vh;
        min-width: 50vh;
        width: 40vw;
        height: 40vh;
        max-width: 40vh;
        max-height: 40vw;
    }

    .section-four {
        display: flex;
        flex-direction: column;
        height: 160vh;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        scroll-snap-align: start;
    }
    .section-four-text{
        min-width: 400px;
        max-width: 400px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: rgb(19, 19, 19);
        text-align:justify;
        align-self: center;
        padding-top: 45px;
       }
    .section-begin-test-image {
        flex-shrink: 0;
        padding-left: 10px;
        min-width:  380px;
        min-height: 400px;
    }
}