.aboutneuro-image-container{
    display:flex;
    align-items:center;
    justify-content: center;
}
.aboutneuro-image-image {
    min-width: 380px;
    min-height: 200px;
    max-width: 900px;
}
.aboutneuro-image-image-small {
    padding-left: 60px;
    padding-right: 60px;
    min-width: 200px;
    min-height: 200px;
    max-width: 500px;
}
.aboutneuro-alltext{
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
}
.aboutneuro-h1{
    font-size: 20px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.aboutneuro-neuroheader {
    font-size: 20px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.aboutneuro-neuroresult {
    margin-bottom: 1em;
    border-left: 5px solid #000000;
    /* border-right: 15px solid #ececec; */
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #f1f1f1;
    padding: 0.5em 1em;
    font-weight: 600;
}
/* .bm-burger-bars {
    background: #373a47;
  } */

.aboutneuro-container {
    padding-top: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.aboutneuro-return {
    color:#f1f1f1;
    font-size: 18px;
    background-color: rgb(61, 104, 184);
    display: flex;
    align-items:center;
    justify-content: center;
}
.aboutneuro-linkdiv {
    color: steelblue;
    font-size: 11px;
    padding-right: 10px;
    text-align: right;
}
.aboutneuro-starttest {
    padding-top: 80px;
    padding-bottom: 10px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.aboutneuro-buttontest {
    min-width: 300px;
    min-height: 60px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #eeeeee;
    background: linear-gradient(90deg, rgb(42, 140, 233) 0%, rgb(28, 111, 189) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(36, 109, 179, 0.644);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    }
@media (max-width: 800px)  {
    .aboutneuro-container {
        padding-top: 0px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}