.alert-dialog-description {
    background-color: rgb(255, 255, 255);
    font-size: 14px !important;
}
.alert-button {
    cursor: pointer;
    padding-right: 10px;
    display: flex;
    font-size: 20px;
}
.alert-button-icon{
    display: flex;
    align-items: center;
    font-size: 32px;
}
.alert-button-icon:hover{
    color: rgb(255, 95, 95);
    transition: 0.6s;
}

#alert-dialog-title {
    color: rgb(39, 106, 173);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}