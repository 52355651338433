.mainshareblock {
    margin-top: 5px;
    margin-bottom: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.shareblock{
    height: 40px;
    gap: 15px;
    display: flex;
    position: relative;
}
.result-div-shareblock {
    display: flex;
  }
.mainshare_div_button {
    filter: grayscale(0.5);
}

.mainshare_div_button:hover {
    filter: grayscale(0.1);
}
.div-text-save-button {
    width: 200px;
    height: 35px;
    font-size: 13px;
    border-radius: 6px;
    background: #0072b1 ;
    color: #ffffff;
    text-align: center;
    padding-top: 8px;
  }
  .shareblocktext {
    position: absolute;
    top:35px;
    left:2dvw;
  }
  @media (max-width: 800px)  {
    .mainshareblock {
        width: 370px;
        display: flex;
        justify-content: space-between;
        gap:50px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .shareblock{
        height: 40px;
        gap: 8px;
        display: flex;
    }
    .div-text-save-button {
      color:#ebe8e8;
      width: 70px;
      height: 55px;
      padding-top: 8px;
      font-size: 13px;
      border-radius: 8px;
      background-image: linear-gradient( #0072b1 0%, #024e77 98.1% );
      text-align: center;
    }
    }