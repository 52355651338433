.checkmodal-closebutton {
    z-index: 30 !important;
    position:absolute;
    right:0px;
    width: 35px;
    height: 35px;
    color: #636262;
    background-color: rgb(255, 255, 255);
    border-radius:20px;
}
.checkmodal-image {
    border-radius: 3px;
}
.checkmodal-button {
    min-width: 290px;
    min-height: 60px;
    color: aliceblue;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: #0072b1;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.checkmodal-block {
    position: relative;
    width: 290px;
}
.checkmodal-header {
    border-radius: 10px;
    padding: 1px;
    color: aliceblue;
    background-color: #0072b1;
    text-align: center;
}
.checkmodal-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}