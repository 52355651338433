@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");

.container404{
  /* width:370px; */
  height:70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  text-align: center;
}

.first-four{
    color: rgba(0, 117, 167, 0.636);
  position: relative;
  font-size:40vmin;
}

.wrong-para{
  font-weight:600;
  color:#092532;
}

.link404{
  color: rgba(36, 116, 150, 0.925);
font-size: 20px;
font-weight:600;
}
.text404 {
  align-items: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  text-align: center;
  /* top: 0vh; */
}