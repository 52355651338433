
.temp {
    padding-top: 20px;
}
.main-partone{
    background-color: rgb(255, 255, 255);
    display:flex;
    font-size: 14px;
    min-width: 350px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.description-partone {
    font-size: 12px;
}

.question-partone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    background: linear-gradient(to bottom right, rgba(188, 209, 214, 0.205) 0%, rgb(255, 255, 255) 100%);
    /* background: rgb(248, 248, 248); */
    border-width: 1px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #a9b9ca;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
}
.question {
    max-width: 280px;
    min-width: 280px;
    padding-right: 10px;
    margin-left: 5px;
}
.main-header{
    text-align: center;
}
.main-header-one{
    text-align: center;
    font-size: 12px;
}

.mainblock{
    padding-top: 0px;
    display: flex;
    flex-flow: wrap;
}

.button-instruction{
    text-align: center;
}

.partonebox {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
}
.partonenext-content {
    margin-top: 40px;
    display: block;
    /* background-color: #1976d2ec; */
    border-radius: 20px;
    width: 800px;
    height: 35px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.next-link{
    padding-top: 5px;
    color: #e4e4e4;
    display: flex;
    justify-content: center;
    font-size: 25px;
}
.partonenext-text {
    color: rgb(6, 109, 199);
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
}
.next-link-content {
    margin-top: 60vh;
    display: block;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    height: 50px;
    background-color: #196dc2;
}
.next-link-result{
    padding-top: 30vh;
    color: #aa201b;
    display: flex;
    justify-content: center;
    font-size: 40px;
}
.hidden {
    display: none;
}
.partone-loading {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.block-name {
    display: none;
    }
@media (max-width: 800px)  {
    .mainblock{
        display: flex;
        flex-direction: column;
    }
    .partonebox {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .partonenext-content {
        margin-top: 40px;
        display: block;
        background-color: #ffffff;
        border-radius: 20px;
        width: 300px;
        height: 60px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .next-link{
        padding-top: 1.2vh;
        margin-top: 60vh;
        color: #dbedff;
        justify-content: center;
        font-size: 18px;
    }
    .next-link-content {
        border-radius: 20px;
        margin-left: 10%;
        margin-right: 10%;
        /* width: 300px; */
        height: 50px;
        background-color: #196dc2;
    }
    .partonenext-text {
        margin-left: 5%;
        margin-right: 5%;
        color: #196dc2;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 22px;
    }
    .next-link-result{
        padding-top: 30vh;
        color: #aa201b;
        display: flex;
        justify-content: center;
        font-size: 40px;
    }
    .partone-loading {
        margin-top: 15%;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .block-name {
        display: block;
        font-size: 0.8rem;
        border-radius: 6px;
        color: aliceblue;
        background-color:#1976D2;
        align-self: center;
        text-align:center;
        width: 320px;;
  }
  .question-partone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    background: linear-gradient(to bottom right, rgba(188, 209, 214, 0.205) 0%, rgb(255, 255, 255) 100%);
    /* background: rgb(248, 248, 248); */
    border-width: 1px;
    margin-left: 7px;
    margin-right: 7px;
    border-color: #a9b9ca;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
}
.question {
    max-width: 280px;
    min-width: 280px;
    padding-right: 8px;
    margin-left: 0px;
}
.main-partone{
    background-color: rgb(255, 255, 255);
    display:flex;
    font-size: 14px;
    min-width: 300px;
    width: 300px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
}