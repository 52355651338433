.email-form {
 margin-top: 80px;
 background-color: rgb(243, 248, 255);
 -webkit-box-shadow: 0px 4px 4px 0px rgba(135,189,255,0.27);
 -moz-box-shadow: 0px 4px 4px 0px rgba(135,189,255,0.27);
 box-shadow: 0px 4px 4px 0px rgba(135,189,255,0.27);
 border-radius: 10px;
 text-align: justify;
 margin-left: auto;
 margin-right: auto;
 padding-left: 10px;
 padding-right: 10px;
 font-size: 16px;
 max-width: 360px;
}
.email-form  h2 {
    font-size: 19px;
    padding-top: 10px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }
.email-form  h3 {
    font-size: 20px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }
  .email-form  .mui-textfield {
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .email-form .mui-send-button {
    top: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    color: rgb(234, 234, 234);
  }
  .email-form .mui-send-icon{
    width: 26px;
    height: 26px;
    color: rgb(234, 234, 234);
  }
.email-form-text {
    font-size: 14px;
    padding-bottom: 10px;
}
@media (max-width: 800px)  {
  .email-form {
    margin-top: 30px;
  }
}