figure {
  justify-self: center;
  justify-content: center;
  justify-items: center;
    margin-left: 200px;
    margin-right: 200px;
  }
  figcaption {
    font-size: 16px;
    border-radius: 6px;
    background: rgb(248, 198, 198);
    padding: 1rem;
    margin: -2rem 0 1rem 16%;
    z-index: 1;
    position: relative;
    width: 80%;
    max-width: 400px;
    text-align: center;
  }
  
  figure + figure figcaption {
    float: right;
    margin: -1rem -1rem 1rem 0;
  }
  
  figure img:not(.hero) {
    background: pink;
  }
  
blockquote {
    padding: 1rem;
    margin: 1rem 10rem 1rem 10rem;
    border:  3px double hotpink;
  }
.p-result {
padding: 10px;
background-color: #f0e0e0;
}
q {
    font-size: 18px;
}
aside {
    margin-left:-5px;
    margin-bottom: 1em;
    border-left: 7px solid #000000;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #f1f1f1;
    padding: 0.5em 1em;
    font-weight: 600;
  }
.result-main-text {
  width: 1000px;

}
.pie {
    position:relative;
    margin: auto;
    padding-right:  0px;
    min-width: 600px;
    max-width: 400px;
}
.figure-blank{
  width: 600px;
}
.div-text{
  padding: 10px;
  text-align: justify;
  width: 1000px;
}
.div-text-table{
  padding: 10px;
  text-align: justify;
  width: 1000px;
  font-weight: 600;
  color: #2e2d2d;
}
.main-partone {
  background-color: #ffffff;
  font-size: 14px;
  min-width: 350px;
  justify-content: center;
}

.random-image {
  margin-top: -60px;
  width: 600px;
  height: 800px;
}
.result-endtest-text {
  color: #202020;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(193, 161, 210);
  padding: 0.7rem;
  margin: -1rem 2 -2rem 25%;
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 67px;
}
.result-nexttest-text {
  color: #202020;
  border-radius: 10px;
  font-size: 20px;
  background-image: linear-gradient(180deg, rgba(233, 240, 252, 0.692) 0%, #91d8ff 50% ,#91d8ff 100%);
  padding: 0.7rem;
  z-index: 1;
  position: relative;
  max-width: 390px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.div-text-attention {
  width: 1000px;
  height: 50px;
  margin-left: 20%;
  margin-right: 20%;
  font-size: 18px;
  border-radius: 6px;
  background: #FC939B;
  text-align: center;
  padding-top: 10px;
}
.div-text-save {
  width: 390px;
  height: 50px;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 18px;
  border-radius: 6px;
  background: #0072b1 ;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.result-endtest-textsmall{
  font-size: 12px;
}
.result-tableprice-firstcolumn-name {
  position: relative;
  font-size: 12px;
  font-weight: 600;
}
.result-tableprice-firstcolumn-badge {
  position: absolute;
  bottom:4px;
  left:-13px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  background-color: rgba(250, 78, 90, 0.877);
  border-radius: 8px;
  color: #ffffff;
  border-width: 0px;
}
@media (max-width: 800px)  {
  .result-tableprice-firstcolumn-badge {
    position: absolute;
    min-width: 118px;
    bottom:20px;
    /* right:5%; */
    left: 3%;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    /* font-size: 0.7em; */
    background-color: rgb(252, 106, 116);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #ffffff;
    border-width: 0px;

  }
  aside {
    margin-left:-15px;
    margin-bottom: 1em;
    border-left: 15px solid #575c65;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #f1f1f1;
    padding: 0.5em 1em;
    font-weight: 600;
  }
  figure {
    display:contents;
    }
    .figure-div{
      width: 350px;
      padding-left: 5%;
      padding-right: 5%;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
    figcaption {
      text-align: center;
      font-size: 1em;
      margin: -2rem 0 1rem 10%;

    }
    .pie {
      position:relative;
      margin: auto;
      padding-right:  0px;
      min-height: 370px;
      min-width: 100%;
      max-width: 600px;
  }
  .main-partone {
    padding-left: 50%;
    padding-right: 50%;
    align-self: center;
    background-color: #ffffff;
    font-size: 14px;
  }
  .random-image {
    height: 440px;
    margin-left: 0%;
  }
  .p-result {
    padding: 8px;
    background-color: #f0e0e0;
    }
    #test-text {
      display: none;
    }
    .result-endtest-text {
      color: #ebe8e8;
      font-size: 20px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient( #0072b1 0%, rgb(43, 181, 209) 98.1% );
      padding: 0.7rem;
      z-index: 1;
      position: relative;
      width: 300px;
      max-width: 370px;
      text-align: center;
    }
    .result-nexttest-text{
      height: 30px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      color: #f1f1f1;
      font-size: 20px;
      justify-content: center;
      border-radius: 10px;
      background-image: linear-gradient( #0072b1 0%, rgb(43, 181, 209) 98.1% );
      z-index: 1;
      width: 320px;
      text-align: center;
      padding-bottom: 40px;
    }
    .result-nexttest-font {
      color: #f4f4f4;
    }
    .div-text{
      padding: 5px;
      text-align: justify;
      width: 355px;
    }
  .div-text-attention {
    width: 350px;
    height: 80px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 18px;
    border-radius: 6px;
    background: #fc939b;
    text-align: center;
    padding-top: 12px;
  }
  .div-text-save {
    color:#ebe8e8;
    width: 350px;
    height: 50px;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 18px;
    border-radius: 6px;
    background-image: linear-gradient( #0072b1 0%, #024e77 98.1% );
    text-align: center;
  }
  .result-main-text {
    width: 370px;
  }
  .result-banner-container {
    display: flex;
    padding-bottom: 15px;
    width: 355px;
  }
  .result-bannerimage{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  }