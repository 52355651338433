$lite:  rgb(146, 146, 146);
$red:  rgba(253, 67, 10, 0.925);
$brand: #489cfc;

.sectionresult {
  min-width: 300px;
  width: 33.33%;
  height: 250px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s linear;
}

// Loader

.loaderresult {
    width: 24px;
    height: 100px;
    border: 2px solid $lite;
    border-radius: 0 0 50px 50px;
    position: relative;
    box-shadow: 0px 0px $brand inset;
    background-image:  linear-gradient($brand 100px, transparent 0);
    background-position: 0px 0px;
    background-size: 29px 100px;
    background-repeat: no-repeat;
    animation: loaderresult 4s linear infinite;
    &::after {
      content: '';
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      border: 2px solid $lite;
      border-radius: 50%;
      width: 28px;
      height: 6px;
    }
    &::before {
      content: '';
      left: 0;
      bottom: -4px;
      border-radius: 50%;
      position: absolute;
      width: 7px;
      height: 7px;
      animation: loaderresults 4s linear infinite;
    }
}

  /* keyFrames */ 

  @keyframes loaderresult {
    0% { background-position: 0px 80px}
    100% { background-position: 0px 0px}
  }
  @keyframes loaderresults {
    0%  { box-shadow: 4px -10px rgba($lite, 0),   6px -0px rgba($lite, 0),      8px -15px rgba($lite, 0),     12px -0px rgba($lite, 0)   }
    20% { box-shadow: 4px -20px rgba($red, 0),   8px -10px rgba($red, 0),     10px -30px rgba($red, 0.5),  15px -5px rgba($red, 0)   }
    40% { box-shadow: 2px -40px rgba($lite, 0.5), 8px -30px rgba($lite, 0.4),   8px -60px rgba($lite, 0.5),   12px -15px rgba($lite, 0.5)}
    60% { box-shadow: 4px -60px rgba($lite, 0.5), 6px -50px rgba($lite, 0.4),   10px -90px rgba($lite, 0.5),  15px -25px rgba($lite, 0.5)}
    80% { box-shadow: 2px -80px rgba($red, 0.5), 4px -70px rgba($red, 0.4),   8px -120px rgba($red, 0),    12px -35px rgba($red, 0.5)}
    100%{ box-shadow: 4px -100px rgba($lite, 0),  8px -90px rgba($lite, 0),     10px -120px rgba($lite, 0),   15px -45px rgba($lite, 0)  }
  }