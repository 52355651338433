.license-main {
  margin-top: 80px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
   max-width: 800px;
}
.license-article {
 text-align: justify;
 margin-left: 10px;
 margin-right: 10px;
}
.license-article h2 {
    font-size: 19px;
    padding-top: 10px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }
.license-article h3 {
    font-size: 16px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }
  .license-article b {
    font-weight: normal;
    font-size: 18px;
    color: rgb(21, 86, 152);
  }
  .license-image {
    min-width: 340px;
    padding-bottom: 20px;
  }
  @media (max-width: 800px)  {
    .license-main {
      margin-top: 10px;
  }}