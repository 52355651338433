.mainheader {
    /* background-image: linear-gradient(180deg, rgba(233, 240, 252, 0.692) 0%, #00b7ff18 50% ,rgb(233, 240, 252, 0.692) 100%);
    background-image: linear-gradient(180deg, rgba(125, 203, 245, 0.53) 0%, #00b7ff47 50% ,rgb(233, 240, 252, 0.692) 100%); */
    background-color:#fcfcfc;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    border-width: 5px;
    border-color:#c7a7c7;
}
.headericonblock {
    display: inline-flex;
}
    .headerneuroicon {
        color: #241523ab;
        font-size: 35px;
        margin-top: 1.25rem/* 20px */;
        margin-bottom: 1.25rem/* 20px */;
        padding-right: 1rem/* 20px */;
        cursor: pointer;
        }
        .headerneuroicon:hover {
        color: #ac1616;
        font-size: 35px;
        }
    .iconandtext-text {
        padding-right: 10px;
        margin-top: 0px;
        font-size: 12px;
        font-weight: lighter;
    }
    .headercopyrighticon {
        color: #241523ab;
        font-size: 35px;
        margin-top: 1.25rem/* 20px */;
        margin-bottom: 1.25rem/* 20px */;
        padding-right: 2rem/* 20px */;
        cursor: pointer;
    }
    .headercopyrighticon:hover {
    color: #11439f;
    font-size: 35px;
    }

    .headersendicon {
    color: #241523ab;
    font-size: 35px;
    margin-top: 1.25rem/* 20px */;
    margin-bottom: 1.25rem/* 20px */;
    margin-left: 1rem/* 20px */;
    margin-right: 1rem/* 20px */;
    cursor: pointer;
    }
    .headersendicon:hover {
    color: #11439f;
    font-size: 35px;
    }
    .create-line {
    margin-top: 15px;
    height: 45px;
    border-left: 1px solid #c9c9c9;
    }

    .headertexticon {
    font-weight: lighter;
    color: #241523e0;
    font-size: 14px;
    margin-top: 1.8rem;
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    }
    .headertexticon:hover {
    color:#650283;
    transition: 0.5s ease-in-out;
    font-size: 14px;
    }

.headericonandtextblock {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.headericonandtextblock:hover {
    color: #ac1616;
}
.headermenu {
    height: 80px;
}

.headermenuicon {
    color: #8CBBE9;
    height: 2rem;
    width: 2rem;
    margin-top: 1.25rem/* 20px */;
    margin-bottom: 1.25rem/* 20px */;
    margin-left: 1.25rem/* 20px */;
    margin-right: 1.25rem/* 20px */;
    cursor: pointer;
    }

.headerrightside {
    display: flex;
    width: 100vw;
    justify-content:space-between;
    align-items: center;
}

.headerlogo {
    display:flex;
    padding-left: 20px;
    color: rgb(37, 37, 37);
    flex-shrink: 0;
    width: 220px;
    font-size: 14px;
}
#android-brain {
    fill: #72088d;
    transform: scale(0.50);
}
.headerlogo-icon {
    height: 35px;
    align-self: center;
    cursor: pointer;
}
.headerlogo-text {
    font-size: 16px;
    align-self: center;
    color: #400250;
}

.headerusericon {
    height: 2.4rem;
    width: 2.4rem;
    color: #481f55;
    justify-items: end;
    margin-top: 0.5rem/* 8px */;
    margin-bottom: 0.5rem/* 8px */;
    margin-left: 0.5rem/* 8px */;
    margin-right: 0.9rem/* 8px */;
    cursor: pointer;

}
.headerusericon-block {
    margin-right: 20px;
    display: block;
    height: 3rem;
    width: 2.6rem;
    background-color: #ffffff;
    border-radius: 1000px;
}
.fullmenu {
    font-size: 16px;
    justify-content: flex-end;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    font-family: Lato, sans-serif;
    color: rgb(34, 34, 34);
}
.fullmenu-li {
    padding: 10px;
    border-radius: 4px;
}
.fullmenu-li:hover {
    color:#045fb9;
    border-radius: 8px;
    transition: 0.4s;
}

.mainheader-test {
    position: fixed;
    background-color: #ffffffc4;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    border-width: 0px;
    border-color: #ffffff40;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(27px);
    z-index: 1000;
}

@media (max-width: 800px)  {
    .headerlogo {
        justify-content: center;
        display:flex;
        padding-left: 20px;
        color: rgb(37, 37, 37);
        flex-shrink: 0;
        width: 80%;
        font-size: 14px;
    }
    .headerlogo-link {
        justify-content: center;
        display:flex;
        flex-shrink: 0;
        width: 80%;
    }
    .headerusericon {
        padding-bottom: 5px;
        padding-right: 7px;
        width: 2.9rem;
        height: 2.4rem;
        color: #481f55;
        cursor: pointer;
    }
    .headerusericon-block {
        margin-right: 15px;
        height: 3rem;
        width: 3rem;
        background-color: #e6e6e6;
        border-radius: 1000px;
    
    }
    .mainheader{
        /* position: block; */
        /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(0, 145, 255) 0%, rgb(43, 181, 209) 90.1% ); */
        background-color:#0072b1;
        height: 60px;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        border-color:#c7a7c7;
        border-width: 0px;
    }
    .fullmenu {
        display:none;
    }
    .headermenuicon {
        color: rgb(255, 255, 255);
        height: 1.8rem;
        width: 1.8rem;
        margin-top: 1.25rem/* 20px */;
        margin-bottom: 1.25rem/* 20px */;
        margin-left: 1.25rem/* 20px */;
        margin-right: 1.25rem/* 20px */;
        }
    .headermenu {
        height: 60px;
        width: 60px;
    }
    /* .headerlogo-icon {
        animation-name: color2;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        -webkit-animation-name: color2;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
    } */
    #android-brain {
        fill: #ffffff;
        transform: scale(0.50);
        animation-name: color2;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        -webkit-animation-name: color2;
        -webkit-animation-duration: 8s;
        -webkit-animation-iteration-count: infinite;
    }
    .headerlogo-text {
        font-size: 14px;
        align-self: center;
        color: rgb(238, 238, 238);
        animation-name: color3;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;
        -webkit-animation-name: color3;
        -webkit-animation-duration: 8s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: 0.5;
    }
    .headericonblock {
        margin-right: 0px;
        display: inline-flex;
    }
    .headerneuroicon {
        display: none;
    }
    .headersendicon {
        color: #ffffffd7;
        font-size: 35px;
        cursor: pointer;
        }
        .headersendicon:hover {
        color: #f6a7a7;
        font-size: 35px;
        }
    .headercopyrighticon {
        display: none;
    }
    .headertexticon {
        display: none;
    }
    .create-line {
        display: none;
        margin-top: 15px;
        height: 45px;
        border-left: 1px solid #ffffff;
      }
}
@keyframes color2 {
    0% {
        fill: #f6f1f7;
        transform: scale(0.5);
    }
    25% {
        fill: #f4d3f9;
        transform: scale(0.52);

    }
    50% {
        fill: #cae9fd;
        transform: scale(0.54);

    }
    75% {
        fill: #faf7c3;
        transform: scale(0.52);

    }
    100% {
        fill: #f6f1f7;
        transform: scale(0.5);
    }
  }
@keyframes color3 {
0% {
    color: #f6f1f7;
}
25% {
    color: #f3d1f9;

}
50% {
    color: #bae1f9;

}
75% {
    color: #f4efaf;

}
100% {
    color: #f6f1f7;
}
}
@media (min-width: 800px)  {
.mainheader {
    position: fixed;
    background-color: #ffffffc4;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    border-width: 0px;
    border-color: #ffffff40;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(27px);
    z-index: 1000;
}
.headermenuicon {
    display: none;
}
.headermenu {
    display: none;
}
}