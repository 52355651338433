.agreemend-main {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
   max-width: 800px;
}

.agreemend-article {
 text-align: justify;
 margin-left: 10px;
 margin-right: 10px;
}
.agreemend-article  h2 {
    font-size: 18px;
    padding-top: 10px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }
.agreemend-article  h3 {
    font-size: 16px;
    align-items: center;
    text-align: center;
    color: rgb(21, 86, 152);
  }