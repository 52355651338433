.headerbannerblock {
    height: 50px;
    display: flex;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.432);
    /* background-color: rgba(125, 60, 141, 0.582); */
    align-items: center;
    border-radius: 6px;
    border-color: #9c5c926c;
    border-width: 1px;
    padding-right: 50px;
    padding-left: 5px;
    margin-top: 12px;
    overflow: hidden;
    right:390px
}
.headerbannerimage {
    filter: contrast(1.2);
    height: 50px;
}
.headerbannertext {
    padding-top: 5px;
    font-weight:300;
    color: rgb(82, 37, 88);
    font-size: 14px;
    cursor: pointer;
}
.headerbannerblock:hover {
    background-color: rgb(252, 242, 199);
    transition: 0.1s ease-in-out;
    }
.bannerstripe {
    background-color: #ffbb00;
    color: #000000;
    position: absolute;
    transform: rotate(55deg);
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    top: 15px;
    right: -25px;
    box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
    -webkit-box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
    -moz-box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
}
@media (max-width: 860px)  {
.headerbannerblock {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top:59px;
    left: 0;
    width: 100vw;
    border-style:solid;
    border-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: #5c7d9c;
    border-width: 0px;
    padding-right: 20px;
    padding-left: 10px;
    padding-bottom: 0px;
    background-color: #0068a0d5;
    overflow: hidden;
    margin-top: 0px;

}
.headerbannerimage {
    height: 50px;
}
.headerbannertext {
    margin: 0 auto;
    position: relative;
    font-weight:300;
    color: rgb(236, 235, 235);
    font-size: 14px;
    cursor: pointer;
}
.bannerstripe {
    background-color: #ffbb00;
    color: #000000;
    position: absolute;
    transform: rotate(45deg);
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    top: 15px;
    right: -10px;
    box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
    -webkit-box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
    -moz-box-shadow: -8px 5px 13px -4px rgba(0,0,0,0.48);
}
.headerbannerblock:hover {
    background-color: #0068a0d5;
    transition: 0.1s ease-in-out;
    }
}