.container {
    max-width: 90px;
    h1 {
      text-align: center;
    }
    .progressbar-container {
      color: rgb(255, 255, 255);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 0%, rgb(218, 247, 253) 90.1% );
      position: relative;
      width: 90px;
      height: 35px;
      border: 1px solid rgba(247, 247, 247, 0.699);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .progressbar-complete {
        position: absolute;
        left: 0;
        top: 0px;
        height: 100%;
        border-radius: 10px;
        z-index: 2;
        filter: blur(0.5px);
        .progressbar-liquid {
          z-index: 1;
          width: 100px;
          height: 100px;
          animation: red 8000ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -moz-animation: red 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -webkit-animation: red 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          position: absolute;
          right: 0px;
          top: -30px;
          border-radius: 40%;
        }
         .progressbar-liquid-green {
              z-index: 1;
              width: 100px;
              height: 100px;
              animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -moz-animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -webkit-animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              position: absolute;
              right: 0px;
              top: -30px;
              border-radius: 40%;
         }
         .progressbar-liquid-blue {
          z-index: 1;
          width: 100px;
          height: 100px;
          animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -moz-animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -webkit-animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          position: absolute;
          right: 0px;
          top: -30px;
          border-radius: 40%;
        }
        .progressbar-liquid-yellow {
          z-index: 1;
          width: 100px;
          height: 100px;
          animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -moz-animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          -webkit-animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          position: absolute;
          right: 0px;
          top: -30px;
          border-radius: 40%;
        }
      }
      .progress {
        color: rgb(22, 22, 22);
        font-size: 14px;
        z-index: 1000;
      }
    }
  }
  
      @keyframes red {
        0% { background-color: #ffb9b9; }
        25% { background-color: #ff9797;}
        50% { background-color: #ff5555; }
        75% { background-color: #ff9797; }
        100% { background-color: #ffb9b9; }
      }
      @keyframes green {
        0% { background-color: #9efdaa; }
        25% { background-color: #7cee8c; }
        50% { background-color: #57e96a; }
        75% { background-color: #7cee8c; }
        100% { background-color: #9efdaa; }
      }
      @keyframes blue {
        0% { background-color: #acbfff; }
        25% { background-color: #8fa9ff; }
        50% { background-color: #7f9cfd; }
        75% { background-color: #8fa9ff; }
        100% { background-color: #acbfff; }
      }
      @keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }
      @-moz-keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }
      
      @-webkit-keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }
  
  @keyframes r {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
  }

.appbar-logo {
  color:rgb(220, 220, 240);
  font-size: 10px;
  margin-top: 46px;
  margin-left: 6px;
}
.appbar {
  align-items: center;
    padding-right: 5px;
    display: flex;
    flex-wrap: nowrap;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    background: #0072b1;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
}

.button-instruction{
    text-align: center;
}

.main-header {
    color: rgb(34, 34, 34);
}

.main-header-one {
  min-width: 400px;
  display: flex;
  justify-content: space-around;
    color: rgb(34, 34, 34);   
}
.mainappbar-link {
    color: rgb(240, 240, 240);
}
.neuro-text {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 13px;
  color: rgb(236, 233, 233);
  justify-self: center;
  justify-items: center;
}
/////////////////////////////// media

@media (max-width: 800px)  {
  .button-instruction {
    display: none;
    text-align: center;
    font-size: 10px;
  }
  .main-header-one {
    min-width: 290px;
    display: flex;
      color: rgb(235, 235, 235);   
  }
  .container {
        max-width: 70px;
        h1 {
          text-align: center;
        }
        .progressbar-container {
          color: rgb(255, 255, 255);
          background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 0%, rgb(218, 247, 253) 90.1% );
          position: relative;
          width: 100%;
          height: 30px;
          border: 0.3px solid rgb(224, 224, 224);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          padding-top: 3px;
          .progressbar-complete {
            position: absolute;
            left: 0;
            top: 0px;
            height: 100%;
            border-radius: 10px;
            animation: g 2500ms infinite ease-in-out;
            z-index: 2;
            .progressbar-liquid {
              z-index: 1;
              width: 100px;
              height: 100px;
              animation: red 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -moz-animation: red 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -webkit-animation: red 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              position: absolute;
              right: 0px;
              top: -30px;
              border-radius: 40%;
            }
            .progressbar-liquid-green {
              z-index: 1;
              width: 100px;
              height: 100px;
              animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -moz-animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -webkit-animation: green 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              position: absolute;
              right: 0px;
              top: -30px;
              border-radius: 40%;
            }
            .progressbar-liquid-blue {
              z-index: 1;
              width: 100px;
              height: 100px;
              animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -moz-animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -webkit-animation: blue 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              position: absolute;
              right: 0px;
              top: -30px;
              border-radius: 40%;
            }
            .progressbar-liquid-yellow {
              z-index: 1;
              width: 100px;
              height: 100px;
              animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -moz-animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              -webkit-animation: serotonine 2500ms infinite ease-in-out, r 8000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
              position: absolute;
              right: 0px;
              top: -30px;
              border-radius: 40%;
            }
          }
          .progress {
            z-index: 2;
              color: rgb(26, 26, 26);
              font-size: 11px;
          }
        }
      }

      @keyframes red {
        0% { background-color: #ffb9b9; }
        25% { background-color: #ff9797;}
        50% { background-color: #ff5555; }
        75% { background-color: #ff9797; }
        100% { background-color: #ffb9b9; }
      }
      @keyframes green {
        0% { background-color: #9efdaa; }
        25% { background-color: #7cee8c; }
        50% { background-color: #57e96a; }
        75% { background-color: #7cee8c; }
        100% { background-color: #9efdaa; }
      }
      @keyframes blue {
        0% { background-color: #acbfff; }
        25% { background-color: #8fa9ff; }
        50% { background-color: #7f9cfd; }
        75% { background-color: #8fa9ff; }
        100% { background-color: #acbfff; }
      }
      @keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }
      @-moz-keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }
      
      @-webkit-keyframes serotonine {
        0% { background-color: #f0fcbd; }
        25% { background-color: #e8fa98; }
        50% { background-color: #e1ff5c; }
        75% { background-color: #e8fa98; }
        100% { background-color: #f0fcbd; }
      }

      @keyframes r {
        from { transform: rotate(0deg); }
        from { transform: rotate(360deg); }
      }
    }
