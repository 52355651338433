.firstresult-banner-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    width: 100%;
  }
  .firstresult-bannerimage{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .firstresult-banner-text {
    background-color: #2565ae;
    padding: 5px;
    padding-left: 200px;
    padding-right: 200px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: rgb(243, 243, 243);
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  @media (max-width: 800px)  {
    .firstresult-banner-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      width: 350px;
    }
    .firstresult-bannerimage{
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    .firstresult-banner-text {
      background-color: #2565ae;
      padding: 10px;
      padding-left: 11px;
      padding-right: 12px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      color: rgb(243, 243, 243);
      font-size: 11px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    }