/* Position and sizing of burger button */
.burger-menu-list {
  display: flex !important;
  margin-top: 14px;
}
/* .burger-menu-list:hover {
  background-color: #234d7d;
  border-radius: 20px;
  transition: 0.5s;
} */

.burger-menu-item {
  display: flex;
  color:#e2e2e2d6;
  font-size: 24px;
  margin-right: 20px;
}
.menu-item {
  font-weight:300;
  color:#e2e2e2f0;
  font-size: 17px;
}
.menu-item:hover {
  color:#caa7cf;
  font-size: 17px;
}
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 24px;
    left: 15px;
    top: 18px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ececec;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #b1edff;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 60px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(4, 88, 153) 0%, rgb(39, 163, 188) 90.1% ); */
    /* background-color: #03537e; */
    background-color: #07517f;
    padding: 2.5em 1em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding-top: 0.8em; */
  }
  
  /* Individual item */
  .bm-item {
    display: flex;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.8);
    top: 0px;
  }

  .burger-menu-logo-text {
    font-weight: lighter;
    color:#ffffff;
    text-align: center;
    margin-bottom:20px;
  }
  .burger-line {
    margin-top: 10px;
    height:0.1vh;
    color:#ffffff;
    background-color: rgba(156, 213, 249, 0);
  }
.burger-menu-second{
  padding-top:0vw ;
}
.burger-menu-footer-text {
  text-align: center;
  color:#ffffff;
  font-size: 14px;
  font-weight: lighter;
  margin-top:10vw;
}
.burger-menu-logo-layer {
  color:#28809a;
}
.burger-menu-logo-graph{
  padding-left: 42%;
  margin-bottom:0px;
}
#menu-android-brain {
  fill: #fcfbd9c9;
  transform: scale(0.8);
}
@media (min-height: 700px)  {
  .burger-menu-footer-text {
    text-align: center;
    color:#ffffff;
    font-size: 14px;
    font-weight: lighter;
    margin-top:70%;
  }
}