@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

:link,:visited { 
  text-decoration: none; 
}
ul, ol { 
  list-style: none; 
}
h1,h2,h3,h4,h5,h6,pre,code { 
  font-size: 1em; 
}
ul,ol,li,
h1,h2,h3,h4,h5,h6,
pre,body,html,p,blockquote,
form,fieldset,input { 
  margin:0; 
  padding:0; 
}
a img,
:link img,
:visited img {
  border:none; 
}
address { 
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
